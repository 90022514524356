import { observable, action } from 'mobx';
import { RegistrationTwo } from '../domain/registration-two';
import api from '../api';

export class RegistrationTwoStore {
  @observable isLoading = false;

  @observable registrationInput: Partial<RegistrationTwo> = {
    lodge_name: '',
    lodge_number: '',
    miniToken: ''
  };
  @observable validationResult: { [k: string]: string } = {};

  fieldsToFill: string[] = [];

  constructor() {
    this.validationResult = {};
    this.fieldsToFill = [];
  }

  validate = () => {
    const request = this.registrationInput;
    this.validationResult = {};
    this.fieldsToFill = [];

    this.validationResult.lodge_name = (request.lodge_name == '')
      ? '* Required'
      : '';

    this.validationResult.lodge_number = (request.lodge_number == '')
      ? '* Required'
      : '';

    this.fieldsToFill = Object.keys(this.validationResult)

    console.log(this.validationResult);
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  registerUser = async (registrationPreStore, registrationStore) => {
    console.log('registering the user');
    //confirmReferralCode
    this.isLoading = true;
    try {
      let dat = {
          referral_code: registrationPreStore.registrationInput.referral_code,
          name: registrationStore.registrationInput.first_name + ' ' + registrationStore.registrationInput.last_name,
          email: registrationStore.registrationInput.email
      };

      //console.log('======= dat =======');
      //console.log(dat);
      //console.log('============================');

      //const result = await api.getToken(null);
      //console.log('======= getToken =======');
      //console.log(result.token);
      //console.log('============================');
      const result2 = await api.registerUser(dat);
      //console.log('======= registerUser =======');
      //console.log(result2);
      //console.log('============================');
      if(result2.status){
        this.registrationInput.miniToken = result2.miniToken;
        return true;
      }
      else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }

  }

}

export const registrationTwoStore = new RegistrationTwoStore();
(window as any).registrationTwoStore = registrationTwoStore;
