import { observable, action } from 'mobx';
import { ImpromptuMeetingSetupConfirmation } from '../domain/impromptu-meeting-setup-confirmation';
import api from '../api';
import { loginStore } from './login-store';

export class ImpromptuMeetingSetupConfirmationStore {
  @observable isLoading = false;

  @observable typeOfThankYou = '';

  @observable meetingResults: any = [];

  @observable latitude = 0;
  @observable longitude = 0;
  @observable link = '';
  @observable address = '';
  @observable title = '';
  @observable description = '';
  @observable closed = false;
  @observable lodge = null;
  @observable isMobile = false;
  @observable isScheduled = false;

  @observable validationResult: { [k: string]: string } = {};

  constructor() {

  }

  // TODO: needs refinement, not just basing it on window size
  checkIfMobileDevice = () => {
    let tt = window.matchMedia('(max-width: 767px)').matches;
    //console.log("tt: " + tt);
    this.isMobile = tt;
  };

  setIsScheduledTrue = () => {
    this.isScheduled = true;
  };

  setIsScheduledFalse = () => {
    this.isScheduled = false;
  };

  // define the function that finds the users geolocation
  setCoords = () => {
    // if geolocation is supported by the users browser
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // save the geolocation coordinates in two variables
          let coords = position.coords;
          // update the value of userlocation variable
          //setUserLocation({ latitude, longitude });
          console.log(position.coords);
          //console.log(coords);
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          //http://maps.google.com/maps?q=S26.184019549056156,E28.046418206048134
          if (this.latitude > 0)
            this.link += 'N' + this.latitude;
          else
            this.link += 'S' + this.latitude * -1;

          this.link += ','
          if (this.longitude > 0)
            this.link += 'E' + this.longitude;
          else
            this.link += 'W' + this.longitude * -1;

        },
        // if there was an error getting the users location
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    }
    // if geolocation is not supported by the users browser
    else {
      console.error('Geolocation is not supported by this browser.');
      this.isMobile = false;
    }
  };


  makeSuggestion = async (impromptuMeetingSetupStore, impromptuMeetingSetupLocationStore, impromptuMeetingSetupImageStore, loginStore) => {
    this.isLoading = true;

    try {
      let dat = {
          miniToken : loginStore.miniToken,
          suggestion_type : '8',
          date: impromptuMeetingSetupStore.date,
          time: impromptuMeetingSetupStore.time,
          duration: impromptuMeetingSetupStore.duration,
          title: impromptuMeetingSetupStore.title,
          description: impromptuMeetingSetupStore.description,
          isScheduled: impromptuMeetingSetupStore.isScheduled,
          isClosed: impromptuMeetingSetupStore.closed,
          latitude: impromptuMeetingSetupLocationStore.latitude,
          longitude: impromptuMeetingSetupLocationStore.longitude,
          address: impromptuMeetingSetupLocationStore.address,
          lodge_id: impromptuMeetingSetupStore.lodge,
          image_file_name: impromptuMeetingSetupImageStore.image_file_name,
          image_file_binary: impromptuMeetingSetupImageStore.image_file_binary
      };

      console.log('======= dat =======');
      console.log(dat);
      console.log('============================');

    //  const result = await api.makeSuggestion(dat);
      //const result = await api.getToken(null);
      //console.log('======= getToken =======');
      //console.log(result.token);
      //console.log('============================');
      //await api.makeSuggestion('', dat);
      //const result2 = await api.makeSuggestion(result.token, dat);
      const result2 = await api.makeSuggestion(dat, loginStore.authToken);
      //const result2 = await api.makeSuggestionTwo(dat);
      console.log('======= makeSuggestion =======');
      //this.lodgeResults = result2;
      console.log(result2);
      console.log('============================');
      console.log(result2.status);
      console.log('============================');
      if (result2.status == "Successful") {
        return true;
      }
      else {
        return false;
      }
      //return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };


}

export const impromptuMeetingSetupConfirmationStore = new ImpromptuMeetingSetupConfirmationStore();
(window as any).impromptuMeetingSetupConfirmationStore = impromptuMeetingSetupConfirmationStore;
