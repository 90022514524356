import { observable, action } from 'mobx';
import { FeedWall } from '../domain/feed-wall';
import api from '../api';
import { loginStore } from './login-store';

export class FeedWallStore {
  @observable isLoading = false;
  @observable userFeedPromotedNotices: any = [];
  @observable userFeedSponsoredNoticesRightPanel: any = [];
  @observable userFeedUpcomingMeetings: any = [];
  @observable userFeedTopThreeOnLeaderboard: any = [];

  @observable input = '';

  @observable scrollCount = 0;

  constructor() {}

  getUserFeed = async (miniToken) => {
    let dat = {
      miniToken: miniToken
    };

    let result = [];
    try {
      result = await api.getUserFeed(dat, loginStore.authToken);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      console.log('======= getUserFeed =======');
      this.userFeedPromotedNotices = result['promotedNotices'];
      this.userFeedSponsoredNoticesRightPanel = result['sponsoredNoticesRightPanel'];
      this.userFeedUpcomingMeetings = result['upcomingMeetings'];
      this.userFeedTopThreeOnLeaderboard = result['topThreeOnLeaderboard'];
      console.log(result);
      console.log('============================');
      //this.isLoading = false;
    }
  };

  getUserFeedWallOnly = async (miniToken) => {
    let result = [];
    try {
      this.scrollCount += 10;

      let dat = {
        miniToken: miniToken,
        scrollCount: this.scrollCount
      };

      result = await api.getUserFeedWallOnly(dat, loginStore.authToken);
      //return true;
    } catch (error) {
      console.error(error);
      //return false;
    } finally {
      console.log('======= getUserFeedWallOnly =======');
      //this.userFeedSponsoredNotices = result['sponsoredNotices'];
      //this.userFeedSponsoredNoticesRightPanel = result['sponsoredNoticesRightPanel'];
      //this.userFeedUpcomingMeetings.concat(result['upcomingMeetings']);
      this.userFeedUpcomingMeetings = [...this.userFeedUpcomingMeetings, ...result['upcomingMeetings']];
      //this.userFeedTopThreeOnLeaderboard = result['topThreeOnLeaderboard'];
      console.log(result);
      console.log('============================');
      //this.isLoading = false;
    }
  };


}

export const feedWallStore = new FeedWallStore();
(window as any).feedWallStore = feedWallStore;
