import { observable, action } from 'mobx';
//import { saveAs, encodeBase64 } from '@progress/kendo-file-saver';

import { Profile } from '../domain/profile';
import api from '../api';
import { loginStore } from './login-store';

export class ProfileStore {
  @observable isLoading = false;
  @observable isExpanded = [];
  @observable editResults: any = [];
  @observable referral_code?: string;
  @observable page?: number;
  @observable settings_page?: number;
  @observable feed_input = '';
  @observable feed: any = [];
  @observable settingsInput: Partial<Profile> = {
    enable_notifications: false,
    send_emails: false,
    all_meetings: false,
    only_watch_list: false,
    custom_notifications: false,
    filter_constitutions: [],
    all_socials_notifications: false,
    day_socials_notifications: false,

    email_notification_weekly_meetings: false,
    all_lodge_weekly_notifications: false,
    watch_list_weekly_notifications: false,
    custom_weekly_notifications: false,
    filter_weekly_constitutions: [],
    all_socials_weekly_notifications: false,
    week_socials_weekly_notifications: false,

    email_notification_monthly_meetings: false,
    all_lodge_monthly_notifications: false,
    watch_list_monthly_notifications: false,
    custom_monthly_notifications: false,
    filter_monthly_constitutions: [],
    all_socials_monthly_notifications: false,
    month_socials_monthly_notifications: false,
    email_notification_lodge_attendance_report: false,
    email_notification_for_suggestions: false,
    email_notification_for_new_socials: false,
    display_on_leaderboard: false,
    display_public_profile: false,
    customize_feed: false,

    address: '', // pretty sure we can remove this
    degree: '', // pretty sure we can remove this
    constitution: '', // pretty sure we can remove this
    district: '', // pretty sure we can remove this
    country: '' // pretty sure we can remove this
  };
  @observable userWatchList: any = [];
  @observable toggleMembershipDivs: { [k: number]: boolean } = {};
  @observable postsContextMenu: { [k: number]: boolean } = {};

  @observable validationResult: { [k: string]: string } = {};

  fieldsToFill: string[] = [];
  fieldsToFillUserPost: string[] = [];

  @observable userPostModalInput: { [k: string]: any } = {
    id_type: 'id'
    // TODO remove init
  };
  @observable modalValidationResult: { [k: string]: string } = {};
  @observable userPostModalOpen: boolean = false;
  @observable userPostModalSuccess: boolean = false;
  @observable userPostModalError: boolean = false;

  constructor() {
    this.validationResult = {};
    this.fieldsToFill = [];

    // user post
    this.modalValidationResult = {};
    this.fieldsToFillUserPost = [];
  }

  validate = () => {
    this.validationResult = {};
    this.fieldsToFill = [];

    this.validationResult.feed_input = (this.feed_input == '')
      ? '* Required'
      : '';

    this.fieldsToFill = Object.keys(this.validationResult)

    console.log(this.validationResult);
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  //====== user post modal validation
  validateUserPost = () => {
    this.modalValidationResult = {};
    this.fieldsToFillUserPost = [];

    //console.log('this.userPostModalInput.feed_input');
    //console.log(this.userPostModalInput.feed_input);

    this.modalValidationResult.feed_input = (this.userPostModalInput.feed_input == undefined || this.userPostModalInput.feed_input == '')
      ? '* Required'
      : '';

    if (this.userPostModalInput.attributeToLodge) {
      this.modalValidationResult.lodge = (this.userPostModalInput.lodge == undefined || this.userPostModalInput.lodge == '' || this.userPostModalInput.lodge == null || this.userPostModalInput.lodge == 0)
        ? '* Required'
        : '';
    }

    this.fieldsToFillUserPost = Object.keys(this.modalValidationResult)

    //console.log(this.modalValidationResult);
    //console.log(this.fieldsToFillUserPost);
  };

  checkValidationUserPost = () => {
    if(this.hasValidationErrorUserPost())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationErrorUserPost = (): boolean => {
    return !!Object.entries(this.modalValidationResult).find(
      ([_, value]) => !!value
    );
  };
  //=================================

  referralCode = (referral_code) => {
    try {
      this.page = 4; // users feed wall
      this.settings_page = 1; //
      this.referral_code = referral_code;
      //console.log('this.referral_code');
      //console.log(this.referral_code);
    } catch (error) {
      // Handle the error here or log it
      console.error("Error updating state:", error);
    }
  };

  emailNotification = (
    enable_notifications,
    send_emails,
    all_meetings,
    only_watch_list,
    custom_notifications,
    filter_constitutions,
    all_socials_notifications,
    day_socials_notifications,

    email_notification_weekly_meetings,
    all_lodge_weekly_notifications,
    watch_list_weekly_notifications,
    custom_weekly_notifications,
    filter_weekly_constitutions,
    all_socials_weekly_notifications,
    week_socials_weekly_notifications,

    email_notification_monthly_meetings,
    all_lodge_monthly_notifications,
    watch_list_monthly_notifications,
    custom_monthly_notifications,
    filter_monthly_constitutions,
    all_socials_monthly_notifications,
    month_socials_monthly_notifications,
    email_notification_lodge_attendance_report,
    email_notification_for_suggestions,
    email_notification_for_new_socials,
    display_on_leaderboard,
    display_public_profile,
    customize_feed
  ) => {
    try {
      this.settingsInput.enable_notifications = enable_notifications;
      this.settingsInput.send_emails = send_emails;
      this.settingsInput.all_meetings = all_meetings;
      this.settingsInput.only_watch_list = only_watch_list;
      this.settingsInput.custom_notifications = custom_notifications;
      this.settingsInput.filter_constitutions = filter_constitutions;
      this.settingsInput.all_socials_notifications = all_socials_notifications;
      this.settingsInput.day_socials_notifications = day_socials_notifications;

      this.settingsInput.email_notification_weekly_meetings = email_notification_weekly_meetings;
      this.settingsInput.all_lodge_weekly_notifications = all_lodge_weekly_notifications;
      this.settingsInput.watch_list_weekly_notifications = watch_list_weekly_notifications;
      this.settingsInput.custom_weekly_notifications = custom_weekly_notifications;
      this.settingsInput.filter_weekly_constitutions = filter_weekly_constitutions;
      this.settingsInput.all_socials_weekly_notifications = all_socials_weekly_notifications;
      this.settingsInput.week_socials_weekly_notifications = week_socials_weekly_notifications;

      this.settingsInput.email_notification_monthly_meetings = email_notification_monthly_meetings;
      this.settingsInput.all_lodge_monthly_notifications = all_lodge_monthly_notifications;
      this.settingsInput.watch_list_monthly_notifications = watch_list_monthly_notifications;
      this.settingsInput.custom_monthly_notifications = custom_monthly_notifications;
      this.settingsInput.filter_monthly_constitutions = filter_monthly_constitutions;
      this.settingsInput.all_socials_monthly_notifications = all_socials_monthly_notifications;
      this.settingsInput.month_socials_monthly_notifications = month_socials_monthly_notifications;
      this.settingsInput.email_notification_lodge_attendance_report = email_notification_lodge_attendance_report;
      this.settingsInput.email_notification_for_suggestions = email_notification_for_suggestions;
      this.settingsInput.email_notification_for_new_socials = email_notification_for_new_socials;

      this.settingsInput.display_on_leaderboard = display_on_leaderboard;
      this.settingsInput.display_public_profile = display_public_profile;
      this.settingsInput.customize_feed = customize_feed;
    } catch (error) {
      // Handle the error here or log it
      console.error("Error updating state:", error);
    }

    console.log('!!!!this.settingsInput.filter_constitutions!!!!');
    console.log(this.settingsInput.filter_constitutions);
  };

  toggleExpand = (index) => {
    this.isExpanded[index] = !this.isExpanded[index];
  };

  updateNotifications = async (miniToken) => {
    let dat = {
      miniToken: miniToken,
      enable_notifications: this.settingsInput.enable_notifications,
      send_emails: this.settingsInput.send_emails,
      all_meetings: this.settingsInput.all_meetings,
      only_watch_list: this.settingsInput.only_watch_list,
      custom_notifications: this.settingsInput.custom_notifications,
      filters: {
        filter_constitutions: this.settingsInput.filter_constitutions,
      },
      all_socials_notifications: this.settingsInput.all_socials_notifications,
      day_socials_notifications: this.settingsInput.day_socials_notifications,

      email_notification_weekly_meetings: this.settingsInput.email_notification_weekly_meetings,
      all_lodge_weekly_notifications: this.settingsInput.all_lodge_weekly_notifications,
      watch_list_weekly_notifications: this.settingsInput.watch_list_weekly_notifications,
      custom_weekly_notifications: this.settingsInput.custom_weekly_notifications,
      filters_weekly: {
        filter_weekly_constitutions: this.settingsInput.filter_weekly_constitutions,
      },
      all_socials_weekly_notifications: this.settingsInput.all_socials_weekly_notifications,
      week_socials_weekly_notifications: this.settingsInput.week_socials_weekly_notifications,

      email_notification_monthly_meetings: this.settingsInput.email_notification_monthly_meetings,
      all_lodge_monthly_notifications: this.settingsInput.all_lodge_monthly_notifications,
      watch_list_monthly_notifications: this.settingsInput.watch_list_monthly_notifications,
      custom_monthly_notifications: this.settingsInput.custom_monthly_notifications,
      filters_monthly: {
        filter_monthly_constitutions: this.settingsInput.filter_monthly_constitutions,
      },
      all_socials_monthly_notifications: this.settingsInput.all_socials_monthly_notifications,
      month_socials_monthly_notifications: this.settingsInput.month_socials_monthly_notifications,
      email_notification_lodge_attendance_report: this.settingsInput.email_notification_lodge_attendance_report,
      email_notification_for_suggestions: this.settingsInput.email_notification_for_suggestions,
      email_notification_for_new_socials: this.settingsInput.email_notification_for_new_socials,
      display_on_leaderboard: this.settingsInput.display_on_leaderboard,
      display_public_profile: this.settingsInput.display_public_profile,
      customize_feed: this.settingsInput.customize_feed
    };

    let result = await api.updateNotifications(dat, loginStore.authToken);
  };

  updateWatchList = async (miniToken) => {
    let dat = {
      miniToken: miniToken,
      action: 'remove'
    };

    let result = await api.updateWatchList(dat, loginStore.authToken);
  };

  getUserWatchList = async (miniToken) => {
    let dat = {
      miniToken: miniToken
    };

    let result = await api.getUserWatchList(dat, loginStore.authToken);

    this.userWatchList = result;
  };

  addNotification = () => {};

  removeNotification = (index) => {};

  suggestMembershipRemoval = async (lodge_id, user_id, loginStore) => {
    this.isLoading = true;

    try {
      let dat = {
          miniToken: loginStore.miniToken, // for created by user
          suggestion_type : '5',
          action : '1',
          lodge_id: lodge_id, // lodge
          user_id: user_id, // user to remove
          proposer: '', // not needed to pass through
          seconder: '', // not needed to pass through
          joined: '' // not needed to pass through
      };

      //console.log('======= dat =======');
      //console.log(dat);
      //console.log('============================');

      //const result = await api.getToken(null);
      //console.log('======= getToken =======');
      //console.log(result.token);
      //console.log('============================');
      //const result2 = await api.claimMembership(result.token, dat);
      const result2 = await api.makeSuggestion(dat, loginStore.authToken);
      console.log(result2);
      console.log('======= makeSuggestion =======');
      console.log(result2.status);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  getUserProfileFeed = async (miniToken) => {
    let dat = {
      miniToken: miniToken
    };

    let result = await api.getUserProfileFeed(dat, loginStore.authToken);

    this.feed = result;
  };

  postToUserProfileFeed = async (miniToken, description) => {
    console.log('image_file_name');
    console.log(this.userPostModalInput.image_file_name);

    //const dataURI = "data:text/plain;base64," + encodeBase64("Hello World!");
    //saveAs(dataURI, "/testtesttest.txt");

    let dat = {};

    if (this.userPostModalInput.attributeToLodge) {
      dat = {
        miniToken: miniToken,
        description: description,
        image_file_name: this.userPostModalInput.image_file_name,
        image_file_data: this.userPostModalInput.image_file_data,
        attribute_to_lodge: this.userPostModalInput.attributeToLodge,
        lodge: this.userPostModalInput.lodge
      };
    }
    else {
      dat = {
        miniToken: miniToken,
        description: description,
        image_file_name: this.userPostModalInput.image_file_name,
        image_file_data: this.userPostModalInput.image_file_data
      };
    }



    let res = false;

    try {
      await api.postToUserProfileFeed(dat, loginStore.authToken);
      this.userPostModalError = false;
      this.userPostModalSuccess = true;
      res = true;
    }
    catch (error) {
      this.userPostModalError = true;
      this.userPostModalSuccess = false;
      res = false;
    }
    finally {
      //console.log(error);
      return res;
    }
  };

  toggleUserPostModal = () => {
    this.userPostModalOpen = !this.userPostModalOpen;
    this.userPostModalError = false;
    this.userPostModalSuccess = false;
  };


  cancelSponsoredPost = async (miniToken, sponsored_post_id) => {
    let dat = {
      miniToken: miniToken,
      sponsored_post_id: sponsored_post_id
    };

    let result = await api.cancelSponsoredPost(dat, loginStore.authToken);
  };

  showContextMenu= (index) => {
    if (this.postsContextMenu[index] == false || this.postsContextMenu[index] == null || this.postsContextMenu[index] == undefined) {
      for (let key in this.postsContextMenu) {
        if (this.postsContextMenu.hasOwnProperty(key)) {
          this.postsContextMenu[key] = false;
        }
      }
      this.postsContextMenu[index] = true;
    }
    else {
      this.postsContextMenu[index] = false;
    }


  }

  hideContextMenu= (index) => {
    this.postsContextMenu[index] = false;
  }

  editUserPost = async (miniToken, user_post_id, action) => {
    let dat = {
      miniToken: miniToken,
      user_post_id: user_post_id,
      action: action
    };

    let res = false;

    try {
      res = await api.editUserPost(dat, loginStore.authToken);
      res = true;
    }
    catch (error) {
      res = false;
    }
    finally {
      //console.log(error);
      return res;
    }
  }


}

export const profileStore = new ProfileStore();
(window as any).profileStore = profileStore;
