// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormGroup
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { ImpromptuMeetingSetupStore } from '../../stores/impromptu-meeting-setup-store';
import { ImpromptuMeetingSetupLocationStore } from '../../stores/impromptu-meeting-setup-location-store';
import { ContributeStore } from '../../stores/contribute-store';

import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';

import { LoginStore } from '../../stores/login-store';

import { QuoteStore } from '../../stores/quote-store';
import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput } from '../inputs/text';
import { TextArea } from '../inputs/text';
import { DateInput } from '../inputs/date';
import { TimeInput } from '../inputs/time';

import { PolicyholderStore } from '../../stores/policyholder-store';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  raffleNameStep: () => void;
  addLodgeStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  impromptuMeetingSetupStore?: ImpromptuMeetingSetupStore;
  impromptuMeetingSetupLocationStore?: ImpromptuMeetingSetupLocationStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('impromptuMeetingSetupStore', 'impromptuMeetingSetupLocationStore', 'contributeStore', 'loginStore')
@observer
export class ImpromptuMeetingSetup extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  addDurationToStartDT = (date, time, duration) => {
    let new_date_time = '';

    const [year, month, day] = date.split('-').map(Number);
    const [hours, minutes] = time.split(':').map(Number);

    // Creating a Date object with the parsed components
    const specificDate = new Date(year, month - 1, day, hours, minutes);
    //console.log("Specific date/time:", specificDate);

    // Adding minutes to the specific date/time
    const minutesToAdd = duration;
    specificDate.setMinutes(specificDate.getMinutes() + minutesToAdd);
    //console.log("Specific date/time:", specificDate);

    new_date_time = specificDate.getFullYear() +
      '-' +
      String(specificDate.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(specificDate.getDate()).padStart(2, '0') +
      ' ' +
      String(specificDate.getHours()).padStart(2, '0') +
      ':' +
      String(specificDate.getMinutes()).padStart(2, '0');

    return new_date_time;
  };

  checkIfToday = (the_date) => {
    // Create a new Date object for the given date
    const givenDate = new Date(the_date); // Replace this with your desired date

    // Get the current date
    const currentDate = new Date();

    // Compare year, month, and day components
    const isToday = (
      givenDate.getFullYear() === currentDate.getFullYear() &&
      givenDate.getMonth() === currentDate.getMonth() &&
      givenDate.getDate() === currentDate.getDate()
    );

    return isToday;
  };

  render() {
    if (!this.props.impromptuMeetingSetupStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const impromptuMeetingSetupStore = this.props.impromptuMeetingSetupStore;
    const impromptuMeetingSetupLocationStore = this.props.impromptuMeetingSetupLocationStore;

    const validationResult = impromptuMeetingSetupStore.validationResult;

    return (
      <div>
      <Row>
        <Col sm="12">
          <h4><u>Setup An Event - Details</u></h4>
        </Col>
      </Row>

        <Card className='post-option-active'>
        <CardBody>

          <p>Enter the details for the event.</p>

          <Row>
              <Col>
              <FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type='checkbox'
                      name='radioClosed'
                      checked={impromptuMeetingSetupStore.isScheduled}
                      onClick={() => {
                        impromptuMeetingSetupStore.isScheduled = !impromptuMeetingSetupStore.isScheduled;
                        //this.props.applicationStore.validationResult = {};

                        impromptuMeetingSetupStore.setIsScheduledTrue();
                      }}
                    />{' '}
                    Schedule meeting
                  </Label>
                </FormGroup>
              </FormGroup>
              </Col>

            {loginStore.level >= 4 ? (
              <Col>
              <FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type='checkbox'
                      name='radioClosed'
                      checked={impromptuMeetingSetupStore.isImmediate}
                      onClick={() => {
                        impromptuMeetingSetupStore.isImmediate = !impromptuMeetingSetupStore.isImmediate;
                        //this.props.applicationStore.validationResult = {};

                        //this.props.setLoadingTrue();// doesn't currently work here
                        //await impromptuMeetingSetupStore.setCoords();
                        //this.props.setLoadingFalse();// doesn't currently work here
                        impromptuMeetingSetupStore.setIsScheduledFalse();
                      }}
                    />{' '}
                    Immediate meeting
                  </Label>
                </FormGroup>
              </FormGroup>
              </Col>
            )
            :
            ''
            }
          </Row>

          <Row>
            <Col>
            <FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type='checkbox'
                    name='radioClosed'
                    checked={impromptuMeetingSetupStore.closed}
                    onClick={() => {
                      impromptuMeetingSetupStore.closed = !impromptuMeetingSetupStore.closed;
                      //this.props.applicationStore.validationResult = {};
                    }}
                  />{' '}
                  Closed meeting
                </Label>
              </FormGroup>
            </FormGroup>
            </Col>
            { impromptuMeetingSetupStore.closed ? (
            <Col>
            <SelectInput
              id='lodge'
              label='Lodge'
              invalid={!!validationResult.lodge}
              validationMessage={validationResult.lodge}
              value={impromptuMeetingSetupStore.lodge}
              onChange={e =>
                (impromptuMeetingSetupStore.lodge = e.target.value)
              }
              options={loginStore.lodges}
            />
            </Col>
            ) : ''
            }
          </Row>

          <Row>
            <Col sm="6">
            <DateInput
                label="Date of Meeting*"
                invalid={!!validationResult.date}
                validationMessage={validationResult.date}
                value={impromptuMeetingSetupStore.date}
                onChange={e => {
                  impromptuMeetingSetupStore.date = e.target.value;
                }}
                id="date"
              />
            </Col>
            <Col sm="6">
              <TimeInput
                  label="Time of Meeting*"
                  invalid={!!validationResult.time}
                  validationMessage={validationResult.time}
                  value={impromptuMeetingSetupStore.time}
                  onChange={e => {
                    impromptuMeetingSetupStore.time = e.target.value;
                  }}
                  id="time"
                />
            </Col>
          </Row>

          <Row>
          <Col>
            <TextInput
              id="title"
              label="Title"
              placeholder="Enter a title for the meeting"
              value={impromptuMeetingSetupStore.title}
              onChange={e =>
                (impromptuMeetingSetupStore.title = e.target.value)
              }
              invalid={!!validationResult.title}
              validationMessage={validationResult.title}
            />
          </Col>
          </Row>
          <Row>
          <Col>
            <TextArea
              id="description"
              label="Description"
              placeholder="Enter a description for the meeting"
              value={impromptuMeetingSetupStore.description}
              onChange={e =>
                (impromptuMeetingSetupStore.description = e.target.value)
              }
              invalid={!!validationResult.description}
              validationMessage={validationResult.description}
            />
          </Col>
          </Row>

          </CardBody>
          </Card>

        <br/>

        <>
          <Button
            color="primary"
            onClick={async () => {
              await impromptuMeetingSetupLocationStore.setCoords();
              this.props.nextStep(); // Use nextStep directly
            }}
          >
            Next
          </Button>
          <Button
            color="primary"
            onClick={this.props.previousStep} // Use previousStep directly
          >
            Back
          </Button>
        </>



        <hr />
        <br />
        <div id='navigation_buttons'>
          <Button color="primary" onClick={this.props.searchStep}>
            Search
          </Button>
          {loginStore.isLoggedIn ? (
          <Button color="primary" onClick={async () => {
            this.props.contributeStore.setEdits(loginStore);
            this.props.contributeStep();
          }}>
            Contribute
          </Button>
          ): ''}
        </div>
      </div>
    );
  }
}

export default ImpromptuMeetingSetup;
