// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody, ModalHeader, ModalBody, ModalFooter, Modal,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import {
  getCurrentYear,
  getPreviousDate,
  getNextDate,
  convertDate,
  getNumberOfDaysInMonth,
  theDay,
  matchMeetingDates,
  convertDateTwo,
  getNameOfDay,
} from '../../helpers/calendar';

import { schedulePlannerStore, SchedulePlannerStore } from '../../stores/schedule-planner-store';
import { ContributeStore } from '../../stores/contribute-store';
import { loginStore, LoginStore } from '../../stores/login-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';

export const MeetingPlanner: React.SFC<Props> = (props) => {
  return (
    <div className="readMoreContent">
      <p>
        <h2>Meeting Planner</h2>
        <div>
          {
            schedulePlannerStore.dayLodgesMeet && schedulePlannerStore.dayLodgesMeet.length > 0 ? (
              schedulePlannerStore.dayLodgesMeet.map((lodge, index) => (
                <div className={`small-font ${
                  lodge.matched
                    ? 'matched'
                    : ''
                }`}>
                  <span>{lodge.time}: </span>
                  <a href="#"
                     onClick={async () => {
                       props.setLoadingTrue();
                       props.lodgeDetailsStore.clearLodgeInfo();
                       await props.lodgeDetailsStore.setLodgeInfo(
                         lodge.name,
                         lodge.number,
                         lodge.degree_id,
                         loginStore.miniToken,
                         loginStore.lodges,
                       );
                       props.lodgeDetailsStore.previousPage = 'schedule-planner';
                       props.lodgeDetailsStep();
                       props.setLoadingFalse();
                     }}>{lodge.name} No. {lodge.number} {lodge.short_constitution} ({lodge.degree})</a>
                </div>
              ))
            ) : 'No meetings...'
          }
        </div>
      </p>
    </div>
  );
};


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  profileStep: () => void;
  lodgeDetailsStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  schedulePlannerStore?: SchedulePlannerStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
  lodgeDetailsStore?: LodgeDetailsStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  meetingPlannerOpen: boolean;
}

@inject('schedulePlannerStore', 'contributeStore', 'loginStore', 'lodgeDetailsStore')
@observer
export class SchedulePlanner extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      meetingPlannerOpen: false,
    };
  }

  render() {
    if (!this.props.schedulePlannerStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const schedulePlannerStore = this.props.schedulePlannerStore;

    return (
      <div>
        <Row>
          <Col sm='12'>
            <h4>
              <u>Schedule Planner</u>
            </h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
          <CardBody className='top-buttons'>
            <Row className='row-justified-and-centered'>
              <Col>
                <div id='navigation_buttons'>
                  <Button
                    className=''
                    color=''
                    onClick={async () => {
                      //this.props.previousStep();
                      //clearInterval(this.props.lodgeDetailsStore.theTimer);
                      //this.props.lodgeDetailsStore.theTimer = null;
                      //this.props.lodgeDetailsStore.todaysMeetingTime = '';

                      this.props.profileStep();
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      height='14'
                      width='14'
                      id='Return-2--Streamline-Core'
                    >
                      <desc>Return 2 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='return-2--arrow-return-enter-keyboard'>
                        <path
                          id='Union'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M6.545 0.998a1 1 0 0 0 0 2h2.728a2.638 2.638 0 0 1 0 5.275H4.817V6.545a1 1 0 0 0 -1.707 -0.707L0.384 8.564a1.004 1.004 0 0 0 -0.22 1.09c0.049 0.119 0.121 0.23 0.218 0.327l2.728 2.728a1 1 0 0 0 1.707 -0.707v-1.729h4.456a4.638 4.638 0 1 0 0 -9.275H6.545Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                    <span className='feed-button-text'> Back</span>
                  </Button>

                  <Button
                    className=''
                    color=''
                    onClick={async () => {
                      //this.props.previousStep();
                      //clearInterval(this.props.lodgeDetailsStore.theTimer);
                      //this.props.lodgeDetailsStore.theTimer = null;
                      //this.props.lodgeDetailsStore.todaysMeetingTime = '';

                      schedulePlannerStore.page = 2;
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Blank-Calendar--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Blank Calendar Streamline Icon: https://streamlinehq.com</desc>
                      <g id='blank-calendar--blank-calendar-date-day-month-empty'>
                        <path
                          id='Subtract'
                          fill='#2859c5'
                          fill-rule='evenodd'
                          d='M3.5 0a1 1 0 0 1 1 1v1h5V1a1 1 0 0 1 2 0v1h1A1.5 1.5 0 0 1 14 3.5v1H0v-1A1.5 1.5 0 0 1 1.5 2h1V1a1 1 0 0 1 1 -1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                        <path
                          id='Rectangle 481'
                          fill='#8fbffa'
                          d='M0 4.5h14v8a1.5 1.5 0 0 1 -1.5 1.5h-11A1.5 1.5 0 0 1 0 12.5v-8Z'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                    <span className='feed-button-text'> Calendar View</span>
                  </Button>

                  <Button
                    className=''
                    color=''
                    onClick={async () => {
                      //this.props.nextStep();
                      //clearInterval(this.props.lodgeDetailsStore.theTimer);
                      //this.props.lodgeDetailsStore.theTimer = null;
                      //this.props.lodgeDetailsStore.todaysMeetingTime = '';

                      schedulePlannerStore.page = 1;
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Bullet-List--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Bullet List Streamline Icon: https://streamlinehq.com</desc>
                      <g id='bullet-list--points-bullet-unordered-list-lists-bullets'>
                        <path
                          id='Union'
                          fill='#2859c5'
                          fill-rule='evenodd'
                          d='M1 3.5a1 1 0 1 0 0 -2 1 1 0 0 0 0 2ZM2 7a1 1 0 1 1 -2 0 1 1 0 0 1 2 0Zm0 4.5a1 1 0 1 1 -2 0 1 1 0 0 1 2 0Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                        <path
                          id='Union_2'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M4 2.5a0.75 0.75 0 0 1 0.75 -0.75h8.5a0.75 0.75 0 0 1 0 1.5h-8.5A0.75 0.75 0 0 1 4 2.5ZM4 7a0.75 0.75 0 0 1 0.75 -0.75h8.5a0.75 0.75 0 0 1 0 1.5h-8.5A0.75 0.75 0 0 1 4 7Zm0.75 3.75a0.75 0.75 0 0 0 0 1.5h8.5a0.75 0.75 0 0 0 0 -1.5h-8.5Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                    <span className='feed-button-text'> List View</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className='post-option-active'>
          <CardBody>
            <span className='small-font-italic'>
              {loginStore.visitationMeetingsFuture.length > 0 ? (
                schedulePlannerStore.page == 1 ? (
                  <div>
                    {loginStore.visitationMeetingsFuture.map((visit, index) => (
                      <div>
                        <span className='small-font'>
                          <b>
                            {visit.datetime} - {visit.lodge_name} No. {visit.lodge_number} -{' '}
                            {parseInt(visit.meeting_type_id) === 1
                              ? `Meeting -  ${visit.meeting_description}`
                              : parseInt(visit.meeting_type_id) === 3
                              ? `Emergency Meeting -  ${visit.meeting_description}`
                              : 'Installation'}
                          </b>
                          <b>
                            {parseInt(visit.meeting_description_id) === 9
                              ? ' - ' + visit.meeting_description_extra
                              : ''}
                          </b>
                          <br />
                          {visit.location_name}, {visit.location_address}, {visit.location_city}
                        </span>
                      </div>
                    ))}
                  </div>
                ) : schedulePlannerStore.page == 2 ? (
                  <div className='calendar'>
                    <div className='row-justified-and-centered-flex'>
                      <h4>{schedulePlannerStore.selectedDateString}</h4>
                    </div>
                    <div className='row-justified-and-centered-flex'>
                      <Button
                        className=''
                        color=''
                        onClick={async () => {
                          schedulePlannerStore.selectedDate = getPreviousDate(schedulePlannerStore.selectedDate);
                          schedulePlannerStore.selectedDateString = convertDate(schedulePlannerStore.selectedDate);
                          schedulePlannerStore.getMonthsMeetings(loginStore);
                        }}
                      >
                        <span className='feed-button-text'>Prev</span>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 14 14'
                          id='Button-Previous--Streamline-Core'
                          height='14'
                          width='14'
                        >
                          <desc>Button Previous Streamline Icon: https://streamlinehq.com</desc>
                          <g id='button-previous--button-television-buttons-movies-skip-previous-video-controls'>
                            <path
                              id='Union'
                              fill='#8fbffa'
                              fill-rule='evenodd'
                              d='m4.238 6.056 7.819 -5.4c0.396 -0.25 0.878 -0.183 1.226 0.08 0.269 0.201 0.458 0.519 0.467 0.897v10.7l0 0.004c0 0.884 -0.979 1.457 -1.693 1.005l-7.82 -5.367c-0.65 -0.439 -0.65 -1.48 0 -1.919ZM0 1.742a1 1 0 1 1 2 0l0 10.516a1 1 0 1 1 -2 0V1.742Z'
                              clip-rule='evenodd'
                              stroke-width='1'
                            ></path>
                          </g>
                        </svg>
                      </Button>

                      <Button
                        className=''
                        color=''
                        onClick={async () => {
                          schedulePlannerStore.selectedDate = getNextDate(schedulePlannerStore.selectedDate);
                          schedulePlannerStore.selectedDateString = convertDate(schedulePlannerStore.selectedDate);
                          schedulePlannerStore.getMonthsMeetings(loginStore);
                        }}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 14 14'
                          id='Button-Next--Streamline-Core'
                          height='14'
                          width='14'
                        >
                          <desc>Button Next Streamline Icon: https://streamlinehq.com</desc>
                          <g id='button-next--button-television-buttons-movies-skip-next-video-controls'>
                            <path
                              id='Union'
                              fill='#8fbffa'
                              fill-rule='evenodd'
                              d='M9.51212 6.056 1.6931 0.656892C1.29745 0.406346 0.815218 0.473133 0.466699 0.735097 0.198102 0.936989 0.00892463 1.25481 0.0000077775 1.63264V12.3333C0.000002589 12.3347 0 12.3362 0 12.3376c9.627e-7 0.884 0.978943 1.4562 1.6931 1.0039l7.81902 -5.36654c0.65048 -0.43882 0.65048 -1.48014 0 -1.91896Zm4.23778 -4.31384c0 -0.55229 -0.4477 -1.000003 -1 -1.000003 -0.5522 0 -1 0.447713 -1 1.000003l0 10.51564c0 0.5523 0.4478 1 1 1 0.5523 0 1 -0.4477 1 -1l0 -10.51564Z'
                              clip-rule='evenodd'
                              stroke-width='1'
                            ></path>
                          </g>
                        </svg>
                        <span className='feed-button-text'>Next</span>
                      </Button>
                    </div>
                    <p>...</p>
                    <div className='ul'>
                      {Array.from(
                        { length: getNumberOfDaysInMonth(schedulePlannerStore.selectedDate) },
                        (_, i) => i,
                      ).map((day, index) => (
                        <div
                          className={`li ${
                            matchMeetingDates(
                              loginStore.visitationOtherUsersMeetingsFuture,
                              theDay(schedulePlannerStore.selectedDate, day + 1),
                            ) > 0
                              ? 'blue'
                              : ''
                          }`}
                          onClick={async () => {
                            await schedulePlannerStore.getDaysMeetings(
                              theDay(schedulePlannerStore.selectedDate, day + 1),
                              loginStore.visitationOtherUsersMeetingsFuture,
                            );
                            this.setState({ meetingPlannerOpen: true });
                          }}
                        >
                          <time>{day + 1}</time>
                          <span>{getNameOfDay(theDay(schedulePlannerStore.selectedDate, day + 1))}</span>
                          {/*theDay(schedulePlannerStore.selectedDate, day+1)*/}
                          {matchMeetingDates(
                            loginStore.visitationOtherUsersMeetingsFuture,
                            theDay(schedulePlannerStore.selectedDate, day + 1),
                          ) > 0
                            ? matchMeetingDates(
                                loginStore.visitationOtherUsersMeetingsFuture,
                                theDay(schedulePlannerStore.selectedDate, day + 1),
                              )
                            : ''}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  'unknown view'
                )
              ) : (
                'Your schedule is empty'
              )}
            </span>
          </CardBody>
        </Card>
        <Modal isOpen={this.state.meetingPlannerOpen} props={this.props}>
          <ModalHeader toggle={() => this.setState({ meetingPlannerOpen: false })}></ModalHeader>
          <ModalBody>
            <MeetingPlanner
              contributeStep={this.props.contributeStep}
              lodgeDetailsStep={this.props.lodgeDetailsStep}
              lodgeDetailsStore={this.props.lodgeDetailsStore}
              previousStep={this.props.previousStep}
              nextStep={this.props.nextStep}
              searchStep={this.props.searchStep}
              profileStep={this.props.profileStep}
              setLoadingTrue={this.props.setLoadingTrue}
              setLoadingFalse={this.props.setLoadingFalse}
            />
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={() => this.setState({ meetingPlannerOpen: false })}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default SchedulePlanner;
