// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import { observer, inject } from 'mobx-react';
import { FAQsStore } from '../../stores/faqs-store';
import { ContributeStore } from '../../stores/contribute-store';

import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';

import { LoginStore } from '../../stores/login-store';

import { QuoteStore } from '../../stores/quote-store';
import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';

import { PolicyholderStore } from '../../stores/policyholder-store';


interface Props {
  searchStep: () => void;
  contributeStep: () => void;
  fAQsStore?: FAQsStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('fAQsStore', 'contributeStore', 'loginStore',)
@observer
export class FAQs extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false
    };
  }



  render() {
    if (!this.props.fAQsStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const fAQsStore = this.props.fAQsStore;//typeOfThankYou

    return (
      <div>
        <Row>
          <Col sm='12'>
            <h4>
              <u>Frequently Asked Questions</u>
            </h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
          <CardBody>
            <Row
              className='pointing-cursor'
              onClick={() => {
                fAQsStore.toggleQADivs[0] = !fAQsStore.toggleQADivs[0];
              }}
            >
              <Col sm='12'>
                <b>How do I refer a Mason to use the app?</b>

                <span className='faq-toggle'>
                  {fAQsStore.toggleQADivs[0] ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Subtract-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus'>
                        <path
                          id='Vector 1931 (Stroke)'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Add-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Add 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math'>
                        <path
                          id='Union'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  )}
                </span>
              </Col>
            </Row>
            <Row className={`toggle-email-setting-div${fAQsStore.toggleQADivs[0] ? '-open' : '-closed'}`}>
              <Col sm='12'>
                <span className='small-font'>
                  <ol>
                    <li>Log in to the FTG system.</li>
                    <li>Scan your referral QR code on your profile page.</li>
                    <li>
                      You'll be taken to the registration page, which you can only access using an existing member's
                      referral QR code.
                    </li>
                    <li>Check your email for a confirmation code. You'll need this code to finish the registration.</li>
                    <li>Use the temporary password sent upon confirmation to log in for the first time.</li>
                    <li>Once logged in, you'll be asked to change your temporary password.</li>
                  </ol>
                </span>
              </Col>
            </Row>
            <hr />
            <Row
              className='pointing-cursor'
              onClick={() => {
                fAQsStore.toggleQADivs[1] = !fAQsStore.toggleQADivs[1];
              }}
            >
              <Col sm='12'>
                <b>How do I add a new Lodge?</b>

                <span className='faq-toggle'>
                  {fAQsStore.toggleQADivs[1] ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Subtract-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus'>
                        <path
                          id='Vector 1931 (Stroke)'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Add-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Add 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math'>
                        <path
                          id='Union'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  )}
                </span>
              </Col>
            </Row>
            <Row className={`toggle-email-setting-div${fAQsStore.toggleQADivs[1] ? '-open' : '-closed'}`}>
              <Col sm='12'>
                <span className='small-font'>
                  <ol>
                    <li>Visit the search page.</li>
                    <li>Choose the criteria for the lodge you're looking for.</li>
                    <li>Click the "Search" button.</li>
                    <li>
                      When you see the list of results, scroll down to the bottom and click the "Add Lodge" button.
                    </li>
                    <li>Fill in the lodge details.</li>
                    <li>Provide the meeting information.</li>
                    <li>
                      Lastly, enter contact information for the lodge, so someone can guide interested Masons in the
                      right direction.
                    </li>
                    <li>Review the information on the confirmation page to ensure it's accurate.</li>
                    <li>Submit it for review.</li>
                  </ol>
                </span>
              </Col>
            </Row>
            <hr />
            <Row
              className='pointing-cursor'
              onClick={() => {
                fAQsStore.toggleQADivs[2] = !fAQsStore.toggleQADivs[2];
              }}
            >
              <Col sm='12'>
                <b>How do I suggest an edit?</b>

                <span className='faq-toggle'>
                  {fAQsStore.toggleQADivs[2] ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Subtract-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus'>
                        <path
                          id='Vector 1931 (Stroke)'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Add-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Add 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math'>
                        <path
                          id='Union'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  )}
                </span>
              </Col>
            </Row>
            <Row className={`toggle-email-setting-div${fAQsStore.toggleQADivs[2] ? '-open' : '-closed'}`}>
              <Col sm='12'>
                <span className='small-font'>
                  <ol>
                    <li>Visit the lodge details page for the lodge you want to update.</li>
                    <li>Click the "Suggest an Edit" button.</li>
                    <li>Enter the correct information.</li>
                    <li>Review the information on the confirmation page to ensure it's accurate.</li>
                    <li>Submit it for review.</li>
                  </ol>
                </span>
              </Col>
            </Row>
            <hr />
            <Row
              className='pointing-cursor'
              onClick={() => {
                fAQsStore.toggleQADivs[3] = !fAQsStore.toggleQADivs[3];
              }}
            >
              <Col sm='12'>
                <b>How do I vote on a suggestion?</b>

                <span className='faq-toggle'>
                  {fAQsStore.toggleQADivs[3] ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Subtract-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus'>
                        <path
                          id='Vector 1931 (Stroke)'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Add-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Add 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math'>
                        <path
                          id='Union'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  )}
                </span>
              </Col>
            </Row>
            <Row className={`toggle-email-setting-div${fAQsStore.toggleQADivs[3] ? '-open' : '-closed'}`}>
              <Col sm='12'>
                <span className='small-font'>
                  <ol>
                    <li>Visit the contributions page.</li>
                    <li>Choose a suggestion.</li>
                    <li>Cast your vote based on what you know to be true.</li>
                  </ol>
                </span>
              </Col>
            </Row>
            <hr />
            <Row
              className='pointing-cursor'
              onClick={() => {
                fAQsStore.toggleQADivs[4] = !fAQsStore.toggleQADivs[4];
              }}
            >
              <Col sm='12'>
                <b>How do I add myself to a Lodge?</b>

                <span className='faq-toggle'>
                  {fAQsStore.toggleQADivs[4] ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Subtract-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus'>
                        <path
                          id='Vector 1931 (Stroke)'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Add-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Add 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math'>
                        <path
                          id='Union'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  )}
                </span>
              </Col>
            </Row>
            <Row className={`toggle-email-setting-div${fAQsStore.toggleQADivs[4] ? '-open' : '-closed'}`}>
              <Col sm='12'>
                <span className='small-font'>
                  <ol>
                    <li>Visit the lodge details page for the lodge you want to join.</li>
                    <li>Click the "Claim Membership" button.</li>
                    <li>Enter your joining information.</li>
                    <li>Submit it for review.</li>
                  </ol>
                </span>
              </Col>
            </Row>
            <hr />
            <Row
              className='pointing-cursor'
              onClick={() => {
                fAQsStore.toggleQADivs[5] = !fAQsStore.toggleQADivs[5];
              }}
            >
              <Col sm='12'>
                <b>How do I remove a member from a Lodge?</b>

                <span className='faq-toggle'>
                  {fAQsStore.toggleQADivs[5] ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Subtract-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus'>
                        <path
                          id='Vector 1931 (Stroke)'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Add-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Add 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math'>
                        <path
                          id='Union'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  )}
                </span>
              </Col>
            </Row>
            <Row className={`toggle-email-setting-div${fAQsStore.toggleQADivs[5] ? '-open' : '-closed'}`}>
              <Col sm='12'>
                <span className='small-font'>
                  <ol>
                    <li>Go to your profile page.</li>
                    <li>Click on the "Membership" tab.</li>
                    <li>Next to each member of the lodge, you'll see an icon.</li>
                    <li>Click the icon.</li>
                    <li>You will be asked to confirm if you want to suggest the removal of the selected member.</li>
                    <li>Submit it for review.</li>
                  </ol>
                </span>
              </Col>
            </Row>
            <hr />
            <Row
              className='pointing-cursor'
              onClick={() => {
                fAQsStore.toggleQADivs[6] = !fAQsStore.toggleQADivs[6];
              }}
            >
              <Col sm='12'>
                <b>How do I add an Emergency Meeting?</b>

                <span className='faq-toggle'>
                  {fAQsStore.toggleQADivs[6] ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Subtract-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus'>
                        <path
                          id='Vector 1931 (Stroke)'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Add-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Add 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math'>
                        <path
                          id='Union'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  )}
                </span>
              </Col>
            </Row>
            <Row className={`toggle-email-setting-div${fAQsStore.toggleQADivs[6] ? '-open' : '-closed'}`}>
              <Col sm='12'>
                <span className='small-font'>
                  <ol>
                    <li>Visit the lodge details page for the lodge you're interested in.</li>
                    <li>Click the "Emergency Meeting" button.</li>
                    <li>Enter the meeting information.</li>
                    <li>Submit it for review.</li>
                  </ol>
                </span>
              </Col>
            </Row>
            <hr />
            <Row
              className='pointing-cursor'
              onClick={() => {
                fAQsStore.toggleQADivs[7] = !fAQsStore.toggleQADivs[7];
              }}
            >
              <Col sm='12'>
                <b>How do I remove an Emergency Meeting?</b>

                <span className='faq-toggle'>
                  {fAQsStore.toggleQADivs[7] ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Subtract-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus'>
                        <path
                          id='Vector 1931 (Stroke)'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Add-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Add 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math'>
                        <path
                          id='Union'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  )}
                </span>
              </Col>
            </Row>
            <Row className={`toggle-email-setting-div${fAQsStore.toggleQADivs[7] ? '-open' : '-closed'}`}>
              <Col sm='12'>
                <span className='small-font'>
                  <ol>
                    <li>Visit the lodge details page for the lodge you're interested in.</li>
                    <li>Next to each emergency meeting, you'll see an icon.</li>
                    <li>Click the icon.</li>
                    <li>
                      You will be asked to confirm if you want to suggest the removal of the selected emergency meeting.
                    </li>
                    <li>Submit it for review.</li>
                  </ol>
                </span>
              </Col>
            </Row>
            <hr />
            <Row
              className='pointing-cursor'
              onClick={() => {
                fAQsStore.toggleQADivs[8] = !fAQsStore.toggleQADivs[8];
              }}
            >
              <Col sm='12'>
                <b>How to create a Social event?</b>

                <span className='faq-toggle'>
                  {fAQsStore.toggleQADivs[8] ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Subtract-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus'>
                        <path
                          id='Vector 1931 (Stroke)'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Add-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Add 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math'>
                        <path
                          id='Union'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  )}
                </span>
              </Col>
            </Row>
            <Row className={`toggle-email-setting-div${fAQsStore.toggleQADivs[8] ? '-open' : '-closed'}`}>
              <Col sm='12'>
                <span className='small-font'>
                  <ol>
                    <li>Navigate to the Socials page.</li>
                    <li>Click create social.</li>
                    <li>Select the desired options.</li>
                    <li>Specify a date and time.</li>
                    <li>Give your event a name, or title.</li>
                    <li>Give your event a description.</li>
                    <li>Click next to continue.</li>
                    <li>You'll need to provide an address and it's gps coordinates.</li>
                    <li>Click next to continue.</li>
                    <li>Select an image for your event.</li>
                    <li>Click next to continue.</li>
                    <li>Confirm that all the details are correct.</li>
                    <li>Click next to submit your event for review.</li>
                  </ol>
                </span>
              </Col>
            </Row>
            <hr />
            <Row
              className='pointing-cursor'
              onClick={() => {
                fAQsStore.toggleQADivs[9] = !fAQsStore.toggleQADivs[9];
              }}
            >
              <Col sm='12'>
                <b>How does it all work?</b>

                <span className='faq-toggle'>
                  {fAQsStore.toggleQADivs[9] ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Subtract-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus'>
                        <path
                          id='Vector 1931 (Stroke)'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Add-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Add 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math'>
                        <path
                          id='Union'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  )}
                </span>
              </Col>
            </Row>
            <Row className={`toggle-email-setting-div${fAQsStore.toggleQADivs[9] ? '-open' : '-closed'}`}>
              <Col sm='12'>
                <span className='small-font'>
                  <ol>
                    <li>All data in the system is user curated.</li>
                    <li>You make suggestions.</li>
                    <li>You vote on suggestions.</li>
                    <li>Once the voting acceptance threshold has been met, the suggestion becomes live data.</li>
                    <li>
                      Or once the voting rejection threshold has been met, the suggestion is removed from the system.
                    </li>
                  </ol>
                </span>
              </Col>
            </Row>
            <hr />
            <Row
              className='pointing-cursor'
              onClick={() => {
                fAQsStore.toggleQADivs[10] = !fAQsStore.toggleQADivs[10];
              }}
            >
              <Col sm='12'>
                <b>What are suggestions?</b>

                <span className='faq-toggle'>
                  {fAQsStore.toggleQADivs[10] ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Subtract-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus'>
                        <path
                          id='Vector 1931 (Stroke)'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Add-1--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Add 1 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math'>
                        <path
                          id='Union'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                  )}
                </span>
              </Col>
            </Row>
            <Row className={`toggle-email-setting-div${fAQsStore.toggleQADivs[10] ? '-open' : '-closed'}`}>
              <Col sm='12'>
                <span className='small-font'>
                  This can be;
                  <ul>
                    <li>Adding new lodges.</li>
                    <li>Changing lodge information.</li>
                    <li>Claiming membership to lodges.</li>
                    <li>Removing members from lodges.</li>
                    <li>Adding emergency meetings.</li>
                    <li>Removing emergency meetings.</li>
                  </ul>
                </span>
              </Col>
            </Row>
          </CardBody>
        </Card>



      </div>
    );
  }
}

export default FAQs;
