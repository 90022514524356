// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import { observer, inject } from 'mobx-react';
import { LeaderboardStore } from '../../stores/leaderboard-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { ViewProfileStore } from '../../stores/view-profile-store';
import { LoginStore } from '../../stores/login-store';

import { QuoteStore } from '../../stores/quote-store';
import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';

import { PolicyholderStore } from '../../stores/policyholder-store';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  viewProfileStep: () => void;
  leaderboardStore?: LeaderboardStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
  viewProfileStore?: ViewProfileStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('leaderboardStore', 'contributeStore', 'loginStore', 'viewProfileStore')
@observer
export class Leaderboard extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  getCurrentYear = () => {
    const date = new Date();
    const year = date.getFullYear();
    return year;
  }



  render() {
    if (!this.props.leaderboardStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const leaderboardStore = this.props.leaderboardStore;
    const viewProfileStore = this.props.viewProfileStore;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Leaderboards</u></h4>
          </Col>
        </Row>
        <h5>{leaderboardStore.isLoading ? 'Loading...' : ''}</h5>
        <hr />
        <div className="row row-justified">
            <div>
            <Button className={`btn tab ${leaderboardStore.page == 0 ? 'active' : ''}`} onClick={async () => {
              leaderboardStore.page = 0;
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Crown--Streamline-Core" height="14" width="14"><desc>Crown Streamline Icon: https://streamlinehq.com</desc><g id="crown--reward-social-rating-media-queen-vip-king-crown"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M7.41 1.713a0.5 0.5 0 0 0 -0.82 0L3.432 6.225 0.854 3.646A0.5 0.5 0 0 0 0 4v6.5a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2V4a0.5 0.5 0 0 0 -0.854 -0.354l-2.578 2.579L7.41 1.713Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
              <span className="feed-button-text"> Global leaderboard</span>
            </Button>
            </div>
            <div>
            <Button className={`btn tab ${leaderboardStore.page == 1 ? 'active' : ''}`} onClick={async () => {
              leaderboardStore.page = 1;
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Graph-Dot--Streamline-Core" height="14" width="14"><desc>Graph Dot Streamline Icon: https://streamlinehq.com</desc><g id="graph-dot--product-data-bars-analysis-analytics-graph-business-chart-dot"><path id="Vector 2182 (Stroke)" fill="#8fbffa" fill-rule="evenodd" d="M5.581 6.004a0.75 0.75 0 0 1 1.06 -0.037l1.625 1.516A0.75 0.75 0 1 1 7.243 8.58L5.618 7.064a0.75 0.75 0 0 1 -0.037 -1.06Z" clip-rule="evenodd" stroke-width="1"></path><path id="Vector 2183 (Stroke)" fill="#8fbffa" fill-rule="evenodd" d="M11.324 3.655a0.75 0.75 0 0 1 0.448 0.961L10.6 7.835a0.75 0.75 0 1 1 -1.41 -0.513l1.173 -3.22a0.75 0.75 0 0 1 0.961 -0.447Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M1.5 0.75a0.75 0.75 0 0 0 -1.5 0v12.5c0 0.414 0.336 0.75 0.75 0.75h12.5a0.75 0.75 0 0 0 0 -1.5H1.5V9.613l2.332 -2.49a0.75 0.75 0 0 0 -1.095 -1.025L1.5 7.418V0.75Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="#2859c5" fill-rule="evenodd" d="M2.584 5.63a1.998 1.998 0 1 0 3.995 0 1.998 1.998 0 0 0 -3.995 0Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_3" fill="#2859c5" fill-rule="evenodd" d="M9.583 2.662a1.998 1.998 0 1 0 3.997 0 1.998 1.998 0 0 0 -3.997 0Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_4" fill="#2859c5" fill-rule="evenodd" d="M7.112 9.182a2 2 0 1 0 4 0 2 2 0 0 0 -4 0Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
              <span className="feed-button-text"> Top 5s for {this.getCurrentYear()}</span>
            </Button>
            </div>
          </div>
          <hr/>

          {
            leaderboardStore.page == 0 ? (
              <Card>
              <CardBody>
              <Row>
              <Col>
              <div className='leaderboard-scroll'>
              <div className='leaderboard'>
                <table >
                  <tr>
                    <th>Rank</th>
                    <th>User</th>
                    {/*<th title='Suggestions'>Suggs.</th>
                    <th>Votes</th>
                    <th title='Attendance'>Attd.</th>
                    <th title='Referrals'>Refs.</th>*/}
                    <th>Points</th>
                    {/*<th title='Level'>Lvl.</th>*/}
                  </tr>
                <tbody>
                  {leaderboardStore.leaderboardResults.users_leaderboard.map((user_data, index) => (
                    <tr className='pointing-cursor' onClick={async () => {
                      if(user_data.public_id != null) {
                        await this.props.viewProfileStore.setProfile(loginStore.miniToken, user_data.public_id);
                        this.props.viewProfileStep();
                      }
                    }}>
                      <td className='rank'>{
                        user_data.rank
                      }</td>
                      <td className='username'>{user_data.name} (Level {user_data.level})</td>
                      {/*<td>{user_data.suggestion_count}</td>
                      <td>{user_data.vote_count}</td>
                      <td>{user_data.visit_count}</td>
                      <td>{user_data.referral_count}</td>*/}
                      <td>{parseInt(user_data.points.toString())}</td>
                      {/*<td className='rank'>{user_data.level}</td>*/}
                    </tr>
                  ))}
                </tbody>
                </table>
                </div>
              </div>
              </Col>
              </Row>
              </CardBody>
              </Card>
            ) :
            leaderboardStore.page == 1 ? (
              <div>
              <Card>
              <CardBody>
              <Row>
                <Col sm='6'>
                <div className='small-font-italic'>
                  <b>Lodge Attendance:</b>
                  <br/>
                  <ol>
                    {leaderboardStore.leaderboardResults.lodges_attendance_numbers.map((lodge_attendance, index) => (
                      <li>{lodge_attendance.lodge}</li>
                    ))}
                  </ol>
                </div>
                </Col>
                <Col sm='6'>
                <div className='small-font-italic'>
                  <b>User Attendance:</b>
                  <br/>
                  <ol>
                    {leaderboardStore.leaderboardResults.users_attendance_numbers.map((user_attendance, index) => (
                      <li>{user_attendance.user}</li>
                    ))}
                  </ol>
                </div>
                </Col>
              </Row>

              <Row>
                <Col sm='6'>
                <div className='small-font-italic'>
                  <b>Most active:</b>
                  <br/>
                  <ol>
                    {leaderboardStore.leaderboardResults.users_login_numbers.map((user_login, index) => (
                      <li>{user_login.user}</li>
                    ))}
                  </ol>
                </div>
                </Col>
                <Col sm='6'>
                <div className='small-font-italic'>
                  <b>Suggestions made:</b>
                  <br/>
                  <ol>
                    {leaderboardStore.leaderboardResults.users_suggestion_numbers.map((user_suggestion, index) => (
                      <li>{user_suggestion.user}</li>
                    ))}
                  </ol>
                </div>
                </Col>
              </Row>

              <Row>
                <Col sm='6'>
                  <div className='small-font-italic'>
                  <b>Visitation:</b>
                  <br/>
                  <ol>
                    {leaderboardStore.leaderboardResults.users_visitation_numbers.map((user_visitation, index) => (
                      <li>{user_visitation.user}</li>
                    ))}
                  </ol>
                </div>
                </Col>
                <Col sm='6'>
                <div className='small-font-italic'>
                  <b>Constitution and Order Attendance:</b>
                  <br/>
                  <ol>
                    {leaderboardStore.leaderboardResults.constitution_order_attendance_numbers.map((constitution_order, index) => (
                      <li>{constitution_order.constitution_order}</li>
                    ))}
                  </ol>
                </div>
                </Col>
              </Row>

              <Row>
                <Col sm='6'>
                  <div className='small-font-italic'>
                    <b>Meeting Types:</b>
                    <br/>
                    <ol>
                      {leaderboardStore.leaderboardResults.meeting_type_attendance_numbers.map((meeting_type, index) => (
                        <li>{meeting_type.meeting_type}</li>
                      ))}
                    </ol>
                  </div>
                </Col>
                <Col sm='6'>
                  <span>extra</span>
                </Col>
              </Row>

              </CardBody>
              </Card>
              </div>

            ) : ''
          }



      </div>
    );
  }
}

export default Leaderboard;
