import { observable, action } from 'mobx';
import { RegistrationThree } from '../domain/registration-three';
import api from '../api';

export class RegistrationThreeStore {
  @observable isLoading = false;

  @observable registrationInput: Partial<RegistrationThree> = {
    confirmation_code: '',
    email: '',
    miniToken: ''
  };
  @observable validationResult: { [k: string]: string } = {};

  fieldsToFill: string[] = [];

  constructor() {
    this.validationResult = {};
    this.fieldsToFill = [];
  }

  validate = () => {
    const request = this.registrationInput;
    this.validationResult = {};
    this.fieldsToFill = [];

    this.validationResult.confirmation_code = (request.confirmation_code == '')
      ? '* Required'
      : '';

    this.fieldsToFill = Object.keys(this.validationResult)

    console.log(this.validationResult);
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  resendCode = () => {
    //this.getAllSuggestedEdits();
    console.log('resending the 2FA code');
  };

  confirm2FACode = async (registrationStore, registrationTwoStore) => {
    //confirm2FACode
    this.isLoading = true;
    try {
      let e = '';
      if (registrationStore.registrationInput.email == '') {
        e = this.registrationInput.email;
      }
      else {
         e = registrationStore.registrationInput.email;
       }
      let m = '';
      if (registrationStore.registrationInput.miniToken == '') {
        m = this.registrationInput.miniToken;
      }
      else {
        m = registrationStore.registrationInput.miniToken;
      }
      let dat = {
          confirmation_code: this.registrationInput.confirmation_code,
          email: e,
          miniToken: m
      };

      console.log('======= dat =======');
      console.log(dat);
      console.log('============================');

      //const result = await api.getToken(null);
      //console.log('======= getToken =======');
      //console.log(result.token);
      //console.log('============================');
      const result2 = await api.confirm2FACode(dat, null);
      console.log('======= confirm2FACode =======');
      //this.lodgeResults = result2;
      console.log(result2);
      console.log('============================');
      if(result2.status){
        return true;
      }
      else {
        return false;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }

  }

  setConfirmationData(confirmationcode, email, miniToken) {
    this.registrationInput.confirmation_code = confirmationcode;
    this.registrationInput.email = email;
    this.registrationInput.miniToken = miniToken;
  }

}

export const registrationThreeStore = new RegistrationThreeStore();
(window as any).registrationThreeStore = registrationThreeStore;
