import { observable, action } from 'mobx';
import { AddConfirmation } from '../domain/add-confirmation';
import api from '../api';
import { loginStore } from './login-store';

export class AddConfirmationStore {
  @observable isLoading = false;

  @observable lodgeInfo: Partial<AddConfirmation> = {
    country: '',
    district: '',
    name: '',
    number: '',
    constitution: '',
    degree: '',
    address: '',
    meeting_time: '',
    meeting_date: '',
    contact: '',
    contact_number: '',
    website: '',
    email: '',
    language: ''
  };
  @observable validationResult: { [k: string]: string } = {};

  @observable lodgeMeetings: any = [];
  @observable lodgeResults: any = [];

  constructor() {}

  makeSuggestion = async (addLodgeStore, addMeetingStore, addContactStore, loginStore, meeting_string, social_string) => {
    this.isLoading = true;
    const two_parts = meeting_string.split(';');
    const meetings_parts= two_parts[0].split(' ');
    const meeting_time = meetings_parts[0];
    const meeting_date = meetings_parts.slice(1).join(' ');
    const installation_parts= two_parts[1].split(' ');
    const installation_time = installation_parts[0];
    const installation_date = installation_parts.slice(1).join(' ');

    const social_parts= social_string.split(' ');
    const social_time = social_parts[0];
    const social_date = social_parts.slice(1).join(' ');

    let contact_deets = (addContactStore.contactInput.title != '') ? addContactStore.contactInput.title + ' ' : '' + addContactStore.contactInput.name + ' ' + addContactStore.contactInput.surname;
    let meetings = [];
    if(addMeetingStore.lodgeInput.meetings.length > 0)
    {
        addMeetingStore.lodgeInput.meetings.forEach((meeting, index) => {
            //{this.meetingStringToDate(meeting.position,meeting.day,meeting.month, meeting.type)}
            meetings.push(
              {
                meeting_id: 0,
                time: meeting.time,
                week: meeting.position,
                day: meeting.day,
                month: meeting.month,
                type: meeting.type,
                description: 11 // 11 is for No Working
              }
            );
        });
    }

    try {
      let dat = {
          miniToken: loginStore.miniToken,
          suggestion_type: '3',
          lodge : {
              lodge_id : 0,
              name : addLodgeStore.lodgeInput.name,
              number : addLodgeStore.lodgeInput.number,
              constitution : addLodgeStore.lodgeInput.constitution.split('_', 1)[0],
              country : addLodgeStore.lodgeInput.country,
              district : addLodgeStore.lodgeInput.district.split('_', 1)[0],
              degree : addLodgeStore.lodgeInput.degree,
              address : addLodgeStore.lodgeInput.address,
              language : addLodgeStore.lodgeInput.language,
              meeting_time : meeting_time,
              meeting_date : meeting_date,
              installation_time : installation_time,
              installation_date : installation_date,
              active : '0'
          },
          contact : {
              contact_number : addContactStore.contactInput.contact_number,
              second_contact_number : addContactStore.contactInput.contact_number_second,
              contact : contact_deets,
              website : addContactStore.contactInput.website,
              email : addContactStore.contactInput.email
          },
          meeting : meetings
      };

      console.log('======= dat =======');
      console.log(dat);
      console.log('============================');

    //  const result = await api.makeSuggestion(dat);
      //const result = await api.getToken(null);
      //console.log('======= getToken =======');
      //console.log(result.token);
      //console.log('============================');
      //const result2 = await api.makeSuggestion(result.token, dat);
      const result2 = await api.makeSuggestion(dat, loginStore.authToken);
      //const result2 = await api.makeSuggestionTwo(dat);
      console.log('======= makeSuggestion =======');
      //this.lodgeResults = result2;
      console.log(result2);
      console.log('============================');
      console.log(result2.status);
      console.log('============================');
      if (result2.status == "Successful") {
        return true;
      }
      else {
        return false;
      }
      //return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  /*get percentageCompleted() {
    const fieldsFilled = Object.keys(this.quoteInput).filter(key => this.fieldsToFill.includes(key));
    const result = fieldsFilled.length/this.fieldsToFill.length;
    return Math.round(result * 100)
  }*/

}

export const addConfirmationStore = new AddConfirmationStore();
(window as any).addConfirmationStore = addConfirmationStore;
