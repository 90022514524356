import { observable, action } from 'mobx';
import { LogVisitMeeting } from '../domain/log-visit-meeting';
import api from '../api';
import { loginStore } from './login-store';

export class LogVisitMeetingStore {
  @observable isLoading = false;

  @observable logVisitMeetingInput: Partial<LogVisitMeeting> = {
    date: '',
    time: '',
    type: 0,
    lodge: 0,
    description: 0,
    description_extra: '',
    address: 0
  };
  @observable validationResult: { [k: string]: string } = {};

  @observable lodgeResults: any = [];
  @observable selectedLodgeIndex?: number = -1;

  fieldsToFill: string[] = [];

  constructor() {
    //this.validate()
    this.validationResult = { };
    this.fieldsToFill = [];
  }

  validate = () => {
    const request = this.logVisitMeetingInput;
    this.validationResult = { };
    this.fieldsToFill = [];

    this.validationResult.date = (request.date == '')
      ? '* Required'
      : '';

    this.validationResult.time = (request.time == '')
      ? '* Required'
      : '';

    this.validationResult.type = (request.type == 0)
      ? '* Required'
      : '';

    this.validationResult.lodge = (request.lodge == 0)
      ? '* Required'
      : '';

    this.validationResult.description = (request.description == 0)
      ? '* Required'
      : '';

    this.validationResult.address = (request.address == 0)
      ? '* Required'
      : '';

    this.fieldsToFill = Object.keys(this.validationResult)

    console.log(this.validationResult);
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  getSelectedLodge = () => {
    return this.lodgeResults[this.selectedLodgeIndex];
  };

  getAllLodges = async () => {
    /*
    this.validate();
    if (this.hasValidationError()) {
      return;
    }

    const quoteInCents = this.getApiQuoteInput();
    */
    this.isLoading = true;
    try {
      const result = await api.getAllLodges([]);
      console.log('======= getAllLodges =======');
      this.lodgeResults = result;
      console.log(result);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  setSelectedLodgeIndex = (index: number) => {
    this.selectedLodgeIndex = index;
    console.log('setting lodge index');
  };

  setLodges = () => {
    this.getAllLodges();
    console.log('getting all lodges');
  };

  logAttendance = async (date, time, type, lodge, description, description_extra, address, loginStore, meeting_kind, extra) => {
    this.isLoading = true;

    try {
      let dat = {
          date: date,
          time: time,
          // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          type: type,
          lodge: lodge,
          description: description,
          description_extra: description_extra,
          address: address,
          miniToken: loginStore.miniToken
      };

      console.log('======= dat =======');
      console.log(dat);
      console.log('============================');

      //const result = await api.getToken(null);
      const result2 = await api.logAttendance(dat, loginStore.authToken);
      console.log(result2);
      console.log('======= logAttendance =======');
      console.log(result2.status);
      console.log('============================');

      let t_obj = {
        datetime: date+' '+time,
        lodge_id: lodge,
        lodge_name: extra.name,
        lodge_number: extra.number,
        location_id: address,
        location_name: extra.address.split(',')[0], // TODO: this need refinement as not all addresses contructed the same
        location_address: extra.address.split(',')[1], // TODO: this need refinement as not all addresses contructed the same
        location_city: extra.address.split(',')[2], // TODO: this need refinement as not all addresses contructed the same
        meeting_description_id: description,
        meeting_description: extra.meeting_description,
        meeting_description_extra: description_extra,
        meeting_type_id: type
      };

      // if result is true from api that it ahas been successfully been added in DB
      // add it from array in login store
      if (meeting_kind == "future") {
        //loginStore.visitationMeetingsFuture
        loginStore.visitationMeetingsFuture = [...loginStore.visitationMeetingsFuture, t_obj];
        loginStore.visitationMeetingsFuture = loginStore.visitationMeetingsFuture.slice().sort((a, b) => {
          const dateA = new Date(a.datetime).getTime();
          const dateB = new Date(b.datetime).getTime();
          return dateA - dateB;
        });
      }
      else if (meeting_kind == "past") {
        //loginStore.visitationMeetingsPast
        loginStore.visitationMeetingsPast = [...loginStore.visitationMeetingsPast, t_obj];
        loginStore.visitationMeetingsPast = loginStore.visitationMeetingsPast.slice().sort((a, b) => {
          const dateA = new Date(a.datetime).getTime();
          const dateB = new Date(b.datetime).getTime();
          return dateB - dateA;
        });
      }
      else {}

      loginStore.visitationMeetings = [...loginStore.visitationMeetingsFuture, ...loginStore.visitationMeetingsPast];

      //loginStore.visitationMeetings = [...loginStore.visitationMeetings, t_obj];

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  removeLogAttendance = async (datetime, type, lodge, description, address, loginStore, meeting_kind) => {
    this.isLoading = true;

    try {
      let dat = {
          datetime: datetime,
          type: type,
          lodge: lodge,
          description: description,
          address: address,
          miniToken: loginStore.miniToken
      };

      console.log('======= dat =======');
      console.log(dat);
      console.log('============================');

      //const result = await api.getToken(null);
      const result2 = await api.removeLogAttendance(dat, loginStore.authToken);
      console.log(result2);
      console.log('======= removeLogAttendance =======');
      console.log(result2.status);
      console.log('============================');
      // if result is true from api that it ahas been successfully been removed in DB
      // remove it from array in login store
      if (meeting_kind == "future") {
        //loginStore.visitationMeetingsFuture
        loginStore.visitationMeetingsFuture = loginStore.visitationMeetingsFuture.filter(item => item.datetime != datetime);
      }
      else if (meeting_kind == "past") {
        //loginStore.visitationMeetingsPast
        loginStore.visitationMeetingsPast = loginStore.visitationMeetingsPast.filter(item => item.datetime != datetime);
      }
      else {}

      loginStore.visitationMeetings = [...loginStore.visitationMeetingsFuture, ...loginStore.visitationMeetingsPast];

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  /*get percentageCompleted() {
    const fieldsFilled = Object.keys(this.quoteInput).filter(key => this.fieldsToFill.includes(key));
    const result = fieldsFilled.length/this.fieldsToFill.length;
    return Math.round(result * 100)
  }*/

  get selectedLodge () {
    return this.lodgeResults[this.selectedLodgeIndex || 0];
  }
}

export const logVisitMeetingStore = new LogVisitMeetingStore();
(window as any).logVisitMeetingStore = logVisitMeetingStore;
