import { observable, action } from 'mobx';
import { SuggestEditConfirmation } from '../domain/suggest-edit-confirmation';
import api from '../api';
import { loginStore } from './login-store';

export class SuggestEditConfirmationStore {
  @observable isLoading = false;

  @observable lodgeInput: Partial<SuggestEditConfirmation> = {
    country: '',
    district: '',
    name: '',
    number: '',
    constitution: '',
    degree: '',
    language: ''
  };
  @observable validationResult: { [k: string]: string } = {};

  constructor() {}

  makeSuggestion = async (suggestEditLodgeStore, suggestEditMeetingStore, suggestEditContactStore, loginStore, meeting_string) => {
    this.isLoading = true;
    const two_parts = meeting_string.split(';');
    const meetings_parts= two_parts[0].split(' ');
    const meeting_time = meetings_parts[0];
    const meeting_date = meetings_parts.slice(1).join(' ');
    const installation_parts= two_parts[1].split(' ');
    const installation_time = installation_parts[0];
    const installation_date = installation_parts.slice(1).join(' ');

    let contact_deets = (suggestEditContactStore.contactInput.title != '') ? suggestEditContactStore.contactInput.title + ' ' : '' + suggestEditContactStore.contactInput.name + ' ' + suggestEditContactStore.contactInput.surname;
    let meetings = [];
    if(suggestEditMeetingStore.lodgeInput.meetings.length > 0)
    {
        suggestEditMeetingStore.lodgeInput.meetings.forEach((meeting, index) => {
            //{this.meetingStringToDate(meeting.position,meeting.day,meeting.month, meeting.type)}
            if (meeting.type != '3') {
              meetings.push(
                {
                  meeting_id: 0,
                  time: meeting.time,
                  week: meeting.position,
                  day: meeting.day,
                  month: meeting.month,
                  type: meeting.type,
                  description: meeting.description,
                  description_extra: meeting.description_extra
                }
              );
            }
        });
    }

    try {
      let dat = {
          miniToken : loginStore.miniToken,
          suggestion_type : '4',
          lodge : {
              lodge_id : suggestEditLodgeStore.lodgeInput.lodge_id,
              name : suggestEditLodgeStore.lodgeInput.name,
              number : suggestEditLodgeStore.lodgeInput.number,
              constitution : suggestEditLodgeStore.lodgeInput.constitution,
              country : suggestEditLodgeStore.lodgeInput.country,
              district : suggestEditLodgeStore.lodgeInput.district,
              degree : suggestEditLodgeStore.lodgeInput.degree,
              address : suggestEditLodgeStore.lodgeInput.address_id,
              meeting_time : meeting_time,
              meeting_date : meeting_date,
              installation_time : installation_time,
              installation_date : installation_date,
              language : suggestEditLodgeStore.lodgeInput.language,
              active : '0'
          },
          contact : {
              contact_number : suggestEditContactStore.contactInput.contact_number,
              second_contact_number : suggestEditContactStore.contactInput.contact_number_second,
              contact : contact_deets,
              website : suggestEditContactStore.contactInput.website,
              email : suggestEditContactStore.contactInput.email
          },
          meeting : meetings
      };

      console.log('======= dat =======');
      console.log(dat);
      console.log('============================');

    //  const result = await api.makeSuggestion(dat);
      //const result = await api.getToken(null);
      //console.log('======= getToken =======');
      //console.log(result.token);
      //console.log('============================');
      //await api.makeSuggestion('', dat);
      //const result2 = await api.makeSuggestion(result.token, dat);
      const result2 = await api.makeSuggestion(dat, loginStore.authToken);
      //const result2 = await api.makeSuggestionTwo(dat);
      console.log('======= makeSuggestion =======');
      //this.lodgeResults = result2;
      console.log(result2);
      console.log('============================');
      console.log(result2.status);
      console.log('============================');
      if (result2.status == "Successful") {
        return true;
      }
      else {
        return false;
      }
      //return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

}

export const suggestEditConfirmationStore = new SuggestEditConfirmationStore();
(window as any).suggestEditConfirmationStore = suggestEditConfirmationStore;
