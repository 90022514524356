import { observable, action } from 'mobx';
import { NewLocation } from '../domain/new-location';
import api from '../api';
import { loginStore } from './login-store';

export class NewLocationStore {
  @observable isLoading = false;

  @observable newLocationInput: Partial<NewLocation> = {
    name: '',
    address: '',
    city: '',
    region_name: '',
    country: '',
    latitude: '',
    longitude: ''
  };
  @observable validationResult: { [k: string]: string } = {};

  @observable lodgeResults: any = [];
  @observable selectedLodgeIndex?: number = -1;

  fieldsToFill: string[] = [];

  constructor() {
    //this.validate()
    this.validationResult = { };
    this.fieldsToFill = [];
  }

  validate = () => {
    const request = this.newLocationInput;
    this.validationResult = { };
    this.fieldsToFill = [];

    this.validationResult.name = (request.name == '')
      ? '* Required'
      : '';

    this.validationResult.address = (request.address == '')
      ? '* Required'
      : '';

    this.validationResult.city = (request.city == '')
      ? '* Required'
      : '';

    this.validationResult.region_name = (request.region_name == '')
      ? '* Required'
      : '';

    this.validationResult.country = (request.country == '')
      ? '* Required'
      : '';

    this.validationResult.latitude = (request.latitude == '')
      ? '* Required'
      : '';

    this.validationResult.longitude = (request.longitude == '')
      ? '* Required'
      : '';

    this.fieldsToFill = Object.keys(this.validationResult)

    console.log(this.validationResult);
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  addLocation = async (name, address, city, region_name, country, latitude, longitude, loginStore) => {
    this.isLoading = true;

    try {
      let dat = {
          miniToken: loginStore.miniToken,
          suggestion_type : '7',
          action : '0',
          name: name,
          address: address,
          city: city,
          region_name: region_name,
          country: country,
          latitude: latitude,
          longitude: longitude
      };

      //console.log('======= dat =======');
      //console.log(dat);
      //console.log('============================');

      //const result = await api.getToken(null);
      //console.log('======= getToken =======');
      //console.log(result.token);
      //console.log('============================');
      //const result2 = await api.claimMembership(result.token, dat);
      const result2 = await api.makeSuggestion(dat, loginStore.authToken);
      console.log(result2);
      console.log('======= makeSuggestion =======');
      console.log(result2.status);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

}

export const newLocationStore = new NewLocationStore();
(window as any).newLocationStore = newLocationStore;
