// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import { observer, inject } from 'mobx-react';
import { UserAccountStore } from '../../stores/user-account-store';
import {
  creditTransferConfirmationStore,
  CreditTransferConfirmationStore,
} from '../../stores/credit-transfer-confirmation-store';
import { ContributeStore } from '../../stores/contribute-store';

import { LoginStore } from '../../stores/login-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { TextInput } from '../inputs/text';

import { QRCodeCanvas } from 'qrcode.react';
import { constructWAShareLink } from '../../helpers/utilities';
import { CreditTransferInitiateStore } from '../../stores/credit-transfer-initiate-store';
import { CreditTransferAmountStore } from '../../stores/credit-transfer-amount-store';
import { toast } from 'react-toastify';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  profileStep: () => void;
  userAccountStore?: UserAccountStore;
  creditTransferConfirmationStore?: CreditTransferConfirmationStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
  creditTransferInitiateStore?: CreditTransferInitiateStore;
  creditTransferAmountStore?: CreditTransferAmountStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('userAccountStore',
  'contributeStore',
  'loginStore',
  'creditTransferConfirmationStore',
  'creditTransferInitiateStore',
  'creditTransferAmountStore')
@observer
export class CreditTransferConfirmation extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.userAccountStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const userAccountStore = this.props.userAccountStore;//typeOfThankYou

    const validationResult = this.props.creditTransferConfirmationStore.validationResult;

    return (
      <div>
        <Row>
          <Col sm='12'>
            <h4>
              <u>Credit Transfer - Confirmation</u>
            </h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
          <CardBody>
            <Row className=''>
              <Col>
                <span className='small-font'>
                  Your current balance is: <b>{loginStore.user_credits}</b> Credits
                </span>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <p>
          You are about to send {this.props.creditTransferAmountStore.amount} Credits to{' '}
          {this.props.creditTransferInitiateStore.username}.
        </p>
        <p>Enter confirmation code you received via email.</p>
        <Row>
          <Col>
            <TextInput
              id="confirmation_code"
              label="Confirmation Code"
              value={this.props.creditTransferConfirmationStore.confirmation_code}
              onChange={e => {
                (this.props.creditTransferConfirmationStore.confirmation_code = e.target.value)
              }}
              invalid={!!validationResult.lodge_name}
              validationMessage={validationResult.lodge_name}
            />
          </Col>
        </Row>
        <p>Click confirm to proceed.</p>

        <hr />
        <Button
          color='primary'
          onClick={ async () => {
            //suggestEditMeetingStore.validate();
            //const success = suggestEditMeetingStore.checkValidation();
            //console.log('----');
            //console.log(success);
            //console.log('----');
            //if (success) {
            //this.props.nextStep();
            //this.props.searchStep(); //testing only
            //}

            let res = await this.props.creditTransferConfirmationStore.validateConfirmationCode(loginStore.miniToken, this.props.creditTransferInitiateStore.receival_code, this.props.creditTransferAmountStore.amount);

            console.log('==========!!!!!!');
            console.log(res);
            console.log(res['status']);
            console.log('!!!!!!==========');

            if(res['status'] == true) {
              toast.success("Transaction was successful!");
              this.props.profileStep();

            }
            else {
              toast.error("Could not complete transaction!");
            }

            //this.props.profileStep();
          }}
        >
          <span> Confirm</span>
        </Button>
        <Button
          color=''
          onClick={() => {
            this.props.previousStep();
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 14 14'
            height='14'
            width='14'
            id='Return-2--Streamline-Core'
          >
            <desc>Return 2 Streamline Icon: https://streamlinehq.com</desc>
            <g id='return-2--arrow-return-enter-keyboard'>
              <path
                id='Union'
                fill='#8fbffa'
                fill-rule='evenodd'
                d='M6.545 0.998a1 1 0 0 0 0 2h2.728a2.638 2.638 0 0 1 0 5.275H4.817V6.545a1 1 0 0 0 -1.707 -0.707L0.384 8.564a1.004 1.004 0 0 0 -0.22 1.09c0.049 0.119 0.121 0.23 0.218 0.327l2.728 2.728a1 1 0 0 0 1.707 -0.707v-1.729h4.456a4.638 4.638 0 1 0 0 -9.275H6.545Z'
                clip-rule='evenodd'
                stroke-width='1'
              ></path>
            </g>
          </svg>
          <span> Back</span>
        </Button>
        <Button color='' onClick={this.props.profileStep}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 14 14'
            id='Delete-1--Streamline-Core'
            height='14'
            width='14'
          >
            <desc>Delete 1 Streamline Icon: https://streamlinehq.com</desc>
            <g id='delete-1--remove-add-button-buttons-delete-cross-x-mathematics-multiply-math'>
              <path
                id='Union'
                fill='#8fbffa'
                fill-rule='evenodd'
                d='M1.707 0.293A1 1 0 0 0 0.293 1.707L5.586 7 0.293 12.293a1 1 0 1 0 1.414 1.414L7 8.414l5.293 5.293a1 1 0 0 0 1.414 -1.414L8.414 7l5.293 -5.293A1 1 0 0 0 12.293 0.293L7 5.586 1.707 0.293Z'
                clip-rule='evenodd'
                stroke-width='1'
              ></path>
            </g>
          </svg>
          <span> Cancel</span>
        </Button>
      </div>
    );
  }
}

export default CreditTransferConfirmation;
