import { observable, action } from 'mobx';
import { LodgeDetails } from '../domain/lodge-details';
import api from '../api';
import { loginStore } from './login-store';

export class LodgeDetailsStore {
  @observable isDetailsLoading = false;
  @observable isMeetingsLoading = false;
  @observable isMember = false;
  @observable canCheckIn = false;
  @observable todaysMeetingTime = '';
  @observable theTimer = null;

  @observable lodgeInput: Partial<LodgeDetails> = {
    name: '',
    number: '',
    degree: '',
    lodge_id: 0,
    miniToken: ''
  };

  @observable lodgeInfo: any = [];
  @observable lodgeMeetings: any = [];
  @observable lodgeMeetingsTwo: any = [];

  @observable feed: any = [];

  @observable previousPage = '';

  constructor() {
  }

  getLodgeInfo = async () => {
    this.isDetailsLoading = true;
    try {
      let dat = {
        miniToken: this.lodgeInput.miniToken,
        lodge_name: this.lodgeInput.name,
        lodge_number:  this.lodgeInput.number,
        degree: this.lodgeInput.degree
      };
      const result = await api.getLodgeInfoTwo(dat, loginStore.authToken);
      //const result = await api.getLodgeInfo(this.lodgeInput.name, this.lodgeInput.number, this.lodgeInput.degree);
      console.log('======= getLodgeInfo =======');
      this.lodgeInfo = result;
      console.log(result);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isDetailsLoading = false;
    }
  };

  getLodgeMeetings = async () => {
    this.isMeetingsLoading = true;
    try {
      const result = await api.getLodgeMeetings(this.lodgeInput.name, this.lodgeInput.number, this.lodgeInput.degree, null, loginStore.authToken);
      console.log('======= getLodgeMeetings =======');
      this.lodgeMeetings = result;
      console.log(result);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isMeetingsLoading = false;
    }
  };

  getLodgeMeetingsTwo = async () => {
    this.isMeetingsLoading = true;
    try {
      const result = await api.getLodgeMeetingsTwo(this.lodgeInput.name, this.lodgeInput.number, this.lodgeInput.degree, null, loginStore.authToken);
      console.log('======= getLodgeMeetingsTwo =======');
      this.lodgeMeetingsTwo = result;

      // this is a hack to get around this
      // but the getLodgeMeetings and getLodgeMeetingsTwo NEED TO BE REFACTORED!!!!
      // they almost to the same thing , but getLodgeMeetings is more for getting the lodge details
      // and getLodgeMeetingsTwo is more about setting the meetings for updating when suggesting an edit
      this.lodgeMeetings.forEach((element, index) => {
        /*if(this.lodgeMeetingsTwo[index].type == 3) {
          element.attendance = 0;
        }
        else {*/
          element.attendance = this.lodgeMeetingsTwo[index].attendance;
        //}

      });

      console.log(result);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isMeetingsLoading = false;
    }
  };

  getLodgeFeed = async () => {
    //this.isMeetingsLoading = true;
    let dat = {
      lodge_id: this.lodgeInfo[0].id
    };

    try {
      //const result = await api.getLodgeFeed(this.lodgeInput.name, this.lodgeInput.number, this.lodgeInput.degree);
      const result = await api.getLodgeFeed(dat, loginStore.authToken);
      console.log('======= getLodgeFeed =======');
      this.feed = result;
      console.log(result);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      ///this.isMeetingsLoading = false;
    }
  };


  updateWatchList = async (lodge_id, action) => {
    let dat = {
      lodge_id: lodge_id,
      miniToken: this.lodgeInput.miniToken,
      action: (action) ? 'add' : 'remove'
    };

    let result = await api.updateWatchList(dat, loginStore.authToken);
  };

  checkIfToday = (the_date) => {
    // Create a new Date object for the given date
    const givenDate = new Date(the_date); // Replace this with your desired date

    // Get the current date
    const currentDate = new Date();

    // Compare year, month, and day components
    const isToday = (
      givenDate.getFullYear() === currentDate.getFullYear() &&
      givenDate.getMonth() === currentDate.getMonth() &&
      givenDate.getDate() === currentDate.getDate()
    );

    return isToday;
  }

  checkTimeWithinRange = (meetings, meetings_two) => {
    let result = false;
    for (let i = 0; i < meetings.length; i++) {
      const meeting = meetings[i];
      const meeting_two = meetings_two[i];
      try {

          let g = meeting.date + 'T' + meeting_two.time;

          if (this.checkIfToday(meeting.date)) {
            console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
            console.log(g);
            console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
            this.todaysMeetingTime = g;

            const givenDateTime = new Date(g);
            const currentDateTime = new Date();
            // Calculate the difference in milliseconds between the given date and time and the current date and time
            const timeDifference = Math.abs(givenDateTime.getTime() - currentDateTime.getTime());
            console.log(timeDifference);
            //if (timeDifference <= 3600000) { // 3600000 milliseconds = 1 hour
            if (timeDifference <= 36000000) { // 36000000 milliseconds = 1 hour
              //console.log("The given date and time is within one hour of the current date and time.");
              result = true;
              break;
            } else {
              //console.log("The given date and time is NOT within one hour of the current date and time.");
              result = false;
            }
          }
      } catch (error) {
          console.log("Invalid date and time: " + error.message);
      }
    }
    //return result;
    this.canCheckIn = result;
  }

// in progress
  checkTimeWithinRangeEveryX = () => {
    let result = false;
    try {
      const givenDateTime = new Date(this.todaysMeetingTime);
      const currentDateTime = new Date();
      // Calculate the difference in milliseconds between the given date and time and the current date and time
      const timeDifference = Math.abs(givenDateTime.getTime() - currentDateTime.getTime());
      console.log(timeDifference);
      if (timeDifference <= 3600000) { // 3600000 milliseconds = 1 hour
        //console.log("The given date and time is within one hour of the current date and time.");
        result = true;
      } else {
        //console.log("The given date and time is NOT within one hour of the current date and time.");
        result = false;
      }
    } catch (error) {
        console.log("Invalid date and time: " + error.message);
    }
    //return result;
    this.canCheckIn = result;
  }

  /*get percentageCompleted() {
    const fieldsFilled = Object.keys(this.quoteInput).filter(key => this.fieldsToFill.includes(key));
    const result = fieldsFilled.length/this.fieldsToFill.length;
    return Math.round(result * 100)
  }*/

  setSelectedLodgeName = (name: string) => {
    this.lodgeInput.name = name;
    console.log('setting lodge name');
  };

  setLodgeInfo = async (name: string, number: string, degree: string, miniToken: string, lodges) => {
    this.lodgeInput.name = name;
    this.lodgeInput.number = number;
    this.lodgeInput.degree = degree;
    this.lodgeInput.miniToken = miniToken;
    await this.getLodgeInfo();
    await this.getLodgeMeetings();
    await this.getLodgeMeetingsTwo();
    await this.getLodgeFeed();
    console.log('000000000000000000000000000000');
    this.checkTimeWithinRange(lodgeDetailsStore.lodgeMeetings, lodgeDetailsStore.lodgeMeetingsTwo);
    console.log('setting lodge info');

    this.isMember = this.checkMembership(lodges);

  };

  checkMembership = (lodges) => {
    //isMember
    //return false;
    return lodges.some(item => item.hasOwnProperty('id') && item.id == this.lodgeInfo[0].id);
  };

  clearLodgeInfo = () => {
    this.lodgeInfo = [];
    this.lodgeMeetings = [];
    this.lodgeMeetingsTwo = [];
    this.feed = [];
    console.log('clearing lodge info');
  };

  suggestEmergencyMeetingRemoval = async (lodge_id, date, time, loginStore) => {
    //this.isLoading = true;

    try {
      let dat = {
          miniToken: loginStore.miniToken,
          suggestion_type : '6', // 6 = emergency meeting
          action : '1', //0 = add, 1 = remove
          lodge_id: lodge_id,
          date: date,
          time: time,
          description: '',
          address: ''
      };

      //console.log('======= dat =======');
      //console.log(dat);
      //console.log('============================');

      //const result = await api.getToken(null);
      //console.log('======= getToken =======');
      //console.log(result.token);
      //console.log('============================');
      //const result2 = await api.claimMembership(result.token, dat);
      const result2 = await api.makeSuggestion(dat, loginStore.authToken);
      console.log(result2);
      console.log('======= makeSuggestion =======');
      console.log(result2.status);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      //this.isLoading = false;
    }
  };


}

export const lodgeDetailsStore = new LodgeDetailsStore();
(window as any).lodgeDetailsStore = lodgeDetailsStore;
