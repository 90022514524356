import { observable, action } from 'mobx';
import { ResetPasswordEmail } from '../domain/reset-password-email';
import api from '../api';
import bcrypt from 'bcryptjs';

export class ResetPasswordEmailStore {
  @observable isLoading = false;
  @observable isUser = false;
  @observable username = '';


  @observable resetPasswordEmailInput: Partial<ResetPasswordEmail> = {
    username: ''
  };
  @observable validationResult: { [k: string]: string } = {};

  fieldsToFill: string[] = [];

  constructor() {
    this.validationResult = {};
    this.fieldsToFill = [];
  }

  validate = () => {
    const request = this.resetPasswordEmailInput;
    this.validationResult = {};
    this.fieldsToFill = [];

    this.validationResult.username = (request.username == '')
      ? '* Required'
      : '';

    this.fieldsToFill = Object.keys(this.validationResult)

    console.log(this.validationResult);
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  confirmUser = async () => {

    this.isLoading = true;
    let result = null;
    try {

        //let resulta = await api.getToken(null);
        //console.log('======= getToken =======');
        //console.log(resulta.token);
        //console.log('============================');

        let dat = {
          user: this.resetPasswordEmailInput.username
        };
        result = await api.confirmUser(dat, null);

      //return true;
    } catch (error) {
      console.error(error);
      this.isUser = false;
    } finally {

      console.log('======= confirmUser =======');
      console.log(result);
      if(result != null){
        if(result.status === true)
        {
          this.isUser = true;
        }
        else {
          this.isUser = false;
        }
        console.log('============================');
      }
      else {
        this.isUser = false;
      }
      this.isLoading = false;
    }
  };

}

export const resetPasswordEmailStore = new ResetPasswordEmailStore();
(window as any).resetPasswordEmailStore = resetPasswordEmailStore;
