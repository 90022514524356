import { observable, action } from 'mobx';
import { ViewProfile } from '../domain/view-profile';
import api from '../api';
import { loginStore } from './login-store';

export class ViewProfileStore {
  @observable isLoading = false;
  @observable typeOfThankYou = '';
  @observable profileResults: any = [];
  @observable page?: number;
  //@observable feed_input = '';

  constructor() {}

  setProfile = async (miniToken, user_id) => {
    //this.getAllSuggestedEdits(loginStore);
    console.log('!@#!@#!@#!@');
    console.log('setProfile');
    console.log(user_id);

    await this.getProfile(miniToken, user_id);
  };

  getProfile = async (miniToken, user_id) => {
    this.isLoading = true;
    try {
      let dat = {
        miniToken: miniToken, // who is looking at the profile
        public_id: user_id // whos profile is being looked at
      };

      const result = await api.getUserPublicProfile(dat, loginStore.authToken);
      console.log('======= getProfile =======');
      this.profileResults = result;
      console.log(result);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

}

export const viewProfileStore = new ViewProfileStore();
(window as any).viewProfileStore = viewProfileStore;
