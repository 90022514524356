import { observable, action } from 'mobx';
import { ImpromptuMeeting } from '../domain/impromptu-meeting';
import api from '../api';
import { loginStore } from './login-store';

export class ImpromptuMeetingStore {
  @observable isLoading = false;

  @observable typeOfThankYou = '';

  @observable meetingResults: any = [];

  @observable latitude = 0;
  @observable longitude = 0;
  @observable link = '';

  constructor() {

  }

  // define the function that finds the users geolocation
  setCoords = () => {
    // if geolocation is supported by the users browser
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // save the geolocation coordinates in two variables
          let coords = position.coords;
          // update the value of userlocation variable
          //setUserLocation({ latitude, longitude });
          console.log(position.coords);
          //console.log(coords);
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          //http://maps.google.com/maps?q=S26.184019549056156,E28.046418206048134
          if (this.latitude > 0)
            this.link += 'N' + this.latitude;
          else
            this.link += 'S' + this.latitude * -1;

          this.link += ','
          if (this.longitude > 0)
            this.link += 'E' + this.longitude;
          else
            this.link += 'W' + this.longitude * -1;

        },
        // if there was an error getting the users location
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    }
    // if geolocation is not supported by the users browser
    else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  getAllImpromptuMeetings = async (loginStore) => {

    this.isLoading = true;
    let result = [];
    try {
      let dat = {
        miniToken: loginStore.miniToken
      };
      result = await api.getAllImpromptuMeetings(dat, loginStore.authToken);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= getAllImpromptuMeetings =======');
      this.meetingResults = result;
      console.log(result);
      console.log('============================');

      this.isLoading = false;
    }
  };

  setImpromptuMeetings = (loginStore) => {
    this.getAllImpromptuMeetings(loginStore);
    console.log('getting all Impromptu Meetings');
  };

}

export const impromptuMeetingStore = new ImpromptuMeetingStore();
(window as any).impromptuMeetingStore = impromptuMeetingStore;
